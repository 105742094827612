@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  font-family: "Poppins", sans-serif;
  color-scheme: light;
}
* {
  scroll-behavior: smooth;
}
/* 
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #F6F6F5;
  scroll-behavior: smooth;
} */

input,
select {
  outline: none;
}

.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* * {
  scroll-behavior: smooth
} */

/* @layer base {
  body {
    @apply font-sans bg-n-8 text-n-1 text-base;
  }
} */

.rotate-45 {
  @apply rotate-[45deg];
}

.rotate-90 {
  @apply rotate-[90deg];
}

.rotate-135 {
  @apply rotate-[135deg];
}

.rotate-180 {
  @apply rotate-[180deg];
}

.rotate-225 {
  @apply rotate-[225deg];
}

.rotate-270 {
  @apply rotate-[270deg];
}

.rotate-315 {
  @apply rotate-[315deg];
}

.rotate-360 {
  @apply rotate-[360deg];
}

.-rotate-45 {
  @apply rotate-[-45deg];
}

.-rotate-90 {
  @apply rotate-[-90deg];
}

.-rotate-135 {
  @apply rotate-[-135deg];
}

.-rotate-180 {
  @apply rotate-[-180deg];
}

.-rotate-225 {
  @apply rotate-[-225deg];
}

.-rotate-270 {
  @apply rotate-[-270deg];
}

.-rotate-315 {
  @apply rotate-[-315deg];
}

.-rotate-360 {
  @apply rotate-[-360deg];
}

.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.carousel-item {
  scroll-snap-align: start;
}

.carousel-control {
  z-index: 10;
  cursor: pointer;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #A71200;
    border-radius: 5px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}